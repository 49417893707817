import dynamic from 'next/dynamic';
import type { ReactElement } from 'react';
import React, { useState } from 'react';

import Layout from '@/components/layouts';
import CustomMetatag from '@/components/layouts/custom-metatag';
import HeroBanner from '@/components/sections/my24/home/hero-banner';
import { IOffersCTA } from '@/components/sections/shared/offers-cta';
import {
  SPLASH_AVAILABLE,
  SplashSpecialEvent,
} from '@/components/sections/splash/special-event';
import {
  BANNER_DATA,
  CONCEPT_DATA,
  LCS_DATA,
  LINEUP_DATA,
  LINEUP_EURO_5_DATA,
  TESTIMONIAL_DATA,
} from '@/contents/cv/home';
import { MENU_CV_SPECIAL_OFFER } from '@/contents/link';
import { getOffers } from '@/services/contentstack';

const Euro5HomeConcept = dynamic(
  () => import('@/components/sections/euro-5/concept'),
  {
    ssr: false,
  }
);

const LineUp = dynamic(() => import('@/components/sections/euro-5/line-up'), {
  ssr: false,
});

const Lcs = dynamic(() => import('@/components/sections/euro-5/lcs'), {
  ssr: false,
});

const Testimonial = dynamic(
  () => import('@/components/sections/euro-5/testimonial'),
  {
    ssr: false,
  }
);

const Offers = dynamic(
  () => import('@/components/sections/shared/offers-cta'),
  {
    ssr: false,
  }
);

const FooterCVGroup = dynamic(
  () => import('@/components/layouts/cv/footer-group'),
  {
    ssr: false,
  }
);

export async function getStaticProps() {
  const resOffer = await getOffers('cv', 8);

  resOffer.data.entries = resOffer.data.entries.map((o) => ({
    name: o.name,
    img: {
      url: o.img && o.img.url,
    },
    category: o.category.map((c) => ({
      url: c.url,
    })),
    url: o.url,
  }));

  return {
    props: JSON.parse(
      JSON.stringify({
        offers: resOffer.data,
        splashAvailable: SPLASH_AVAILABLE(),
      })
    ),
  };
}
export default function Page(props) {
  const { offers, splashAvailable } = props;

  const OFFER_DATA: IOffersCTA = {
    background: 'dark',
    title: 'ข้อเสนอพิเศษ',
    buttons: [
      {
        label: 'ดูข้อเสนอทั้งหมด',
        url: MENU_CV_SPECIAL_OFFER.url,
        target: MENU_CV_SPECIAL_OFFER.target ?? undefined,
        color: 'button-white',
      },
    ],
    items: offers.entries.map((o) => ({
      image: {
        src: o.img ? o.img.url : '',
        alt: o.name,
      },
      title: o.name,
      button: {
        url: `${MENU_CV_SPECIAL_OFFER.url}/${o.category[0].url}/${o.url}`,
        label: 'ดูรายละเอียด',
        icon: 'arrow-forward',
      },
    })),
  };

  // NOTE: CV_E5
  const metatag = {
    title: ' ISUZU TRUCKS Thailand Official ',
    description:
      'เจ้าแห่งรถบรรทุก อีซูซุ คิงออฟทรัคส์ - ISUZU TRUCKS Thailand Official',
    img: { url: null },
  };

  const [displaySplash, setDisplaySplash] = useState<boolean>(true);
  const onCloseSplash = () => {
    setDisplaySplash(false);
  };

  return (
    <>
      <CustomMetatag
        title={metatag.title}
        description={metatag.description}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        imgURL={!!metatag.img && metatag.img.url}
      />
      <main>
        {!!splashAvailable && !!displaySplash && (
          <SplashSpecialEvent onClose={onCloseSplash} />
        )}
        <HeroBanner {...BANNER_DATA} isCloseSplash />
        <Euro5HomeConcept {...CONCEPT_DATA} />
        <LineUp {...LINEUP_EURO_5_DATA} animate="interactive" />
        <LineUp {...LINEUP_DATA} />
        <Lcs {...LCS_DATA} />
        <Testimonial {...TESTIMONIAL_DATA} />
        <Offers {...OFFER_DATA} />
      </main>
      <FooterCVGroup />
    </>
  );
}

Page.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};
